$nav-height-mobile: 85px;
$nav-height-desktop: 70px;
$promo-height: 42px;

/**
 * Spacings
 */

$spacing-xs: 0.5rem;
$spacing-sm: 1rem;
$spacing-md: 2rem;
$spacing-lg: 4rem;
$spacing-xl: 6.5rem;
$spacing-xxl: 10rem;

/**
 * Border Radius
 */

$br-xs: 5px;
$br-sm: 10px;
$br-md: 15px;
$br-lg: 20px;
$br-xl: 25px;
$br-pill: 9999px;
$br-circle: 50%;
