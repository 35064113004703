@import 'styles/mixins/fonts-2024';
@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';

.container {
	background-color: $white;
	color: $black;

	padding: $spacing-lg 2rem $spacing-xxl;

	position: relative;
	z-index: 2;

	overflow: hidden;

	@include sm-down {
		padding: $spacing-lg 0;

		> * {
			padding: 0 1.5rem;
		}
	}
}

.header {
	h2 {
		@include headline3;
		font-size: clamp(4rem, 8vw, 6.25rem);
		margin-bottom: 0;
		max-width: 14ch;

		@include sm-down {
			margin-bottom: 0;
			@include headline4;
		}
	}

	margin-bottom: clamp(2rem, 4vw, 4rem);
}

.autopilot_intro_container {
	position: relative;
	margin-bottom: clamp(5rem, 10vw, 12rem);
	display: grid;
	grid-template-columns: 1.3fr 1fr;
	align-items: center;
	column-gap: 30px;

	max-width: 1360px;
	margin-inline: auto;
	height: min(80vh, 60rem);

	.autopilot_image > img {
		max-height: 834px;
		object-fit: contain;
		object-position: top right;
	}

	.autopilot_intro {
		padding-left: $spacing-xl;
		max-width: 720px;
	}

	@include sm-down {
		grid-template-columns: 1fr;
		row-gap: 30px;
		align-items: end;

		height: clamp(37rem, 50vh + 17rem, 46rem);

		.autopilot_intro {
			padding-left: 0;
			max-width: none;
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			order: 2;
		}

		.autopilot_image {
			order: 1;

			> img {
				width: 100%;
				max-height: 50vh;
				object-fit: contain;
				object-position: center;
			}
		}
	}

	@include xs-only {
		height: auto;
	}
}

.autopilot_shader {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	filter: brightness(1.5);
	opacity: 0.9;

	z-index: -1;

	@include md-up {
		canvas {
			transform: translateX(30%);
		}
	}

	@include sm-down {
		height: clamp(20rem, 50vh, 30rem);
	}

	> * {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.eyebrow {
	@include eyebrow1;

	color: #a6a6a6;

	text-transform: uppercase;
	margin: 0 auto 1.5rem;

	@include sm-down {
		@include eyebrow2;
		margin: 0 auto 1.5rem;
	}
}

.autopilot_body {
	@include body3;

	max-width: 55ch;
	margin: 0;

	color: #636363;

	@include sm-down {
		@include body4;
		margin: 0 auto;
	}
}

.cta_container {
	display: flex;
	margin-top: 24px;
}

.cta {
	text-transform: uppercase;
	letter-spacing: 2.24px;
	font-weight: 500;
	font-size: 18px;

	span {
		font-weight: 500 !important;
	}
}

.autopilot_video_container {
	display: flex;
	justify-content: center;
	max-width: 80rem;
	margin: $spacing-lg auto 0;

	video {
		width: 100%;
		max-width: 80rem;
		border-radius: 0.5rem;
	}
}

.card_list {
	display: flex;
	flex-direction: column;

	align-items: center;

	list-style: none;
	gap: clamp(6rem, 16vw, 15rem);
}

.card_wrapper {
	max-width: 1220px;
	width: 100%;
	padding: 0 20px;

	&:nth-child(odd) {
		.card {
			grid-template-columns: 1fr 1fr;
		}

		.card_component_container {
			grid-column: 1;
		}

		.footnote {
			grid-column: 2;
		}
	}

	@include md-up {
		img {
			object-fit: cover !important;
		}
	}
}

.card {
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 50px;

	@include sm-down {
		display: block;
	}
}

.card_header {
	max-width: 29rem;
	margin: 0 auto;

	@include sm-down {
		margin-bottom: 2rem;
	}
}

.card_supertitle {
	@include eyebrow1;

	text-transform: uppercase;

	color: rgba(0, 0, 0, 0.25);

	@include sm-down {
		@include eyebrow2;
		margin-bottom: 0.7rem;
	}

	margin-bottom: 1.5rem;
}

.card_title {
	@include headline4;
	margin-bottom: 2rem;

	@include sm-down {
		@include headline5;
		margin-bottom: 1.5rem;
	}

	max-width: 18ch;
	font-weight: 500;
	line-height: 1.1;
}

.card_subtitle {
	@include body3;
	margin-bottom: 1.5rem;

	@include sm-down {
		@include body4;
		margin-bottom: 1.5rem;
	}

	max-width: 50ch;

	color: $black;
}

.card_cta {
	@include eyebrow1;
	text-transform: uppercase;

	@include sm-down {
		@include button2;
	}
}

.card_component_container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	grid-column: 2;
	grid-row: 1 / 3;

	transition: all 0.2s ease-in-out;

	* {
		max-height: 35rem;
	}

	img {
		max-height: 450px;
	}

	@include sm-down {
		margin: 0 auto;

		max-width: 29rem;
	}
}

.card_hint_wrapper {
	display: flex;
	align-items: flex-start;
}

.card_hint {
	max-width: 350px;

	padding: 1.5rem;
	border-radius: 0.5rem;
	background-color: rgba(0, 56, 255, 0.05);

	margin-top: 2rem;

	justify-content: flex-start;

	p {
		@include body3;
		color: $black;
		font-size: 1rem;
	}

	@include sm-down {
		margin-top: 2.5rem;

		p {
			@include body4;
		}
	}
}

.card_icon {
	align-self: start;
}

.card_new_badge {
	color: #5289fe;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 2.32px;
	border-radius: 4px;
	background: rgba(82, 137, 254, 0.2);
	padding: 4px 10px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	vertical-align: middle;
	text-transform: uppercase;

	@include sm-down {
		display: flex;
		max-width: 60px;
		margin-top: 20px;
	}
}

.footnote_mobile,
.footnote_desktop {
	color: #a6a6a6;
	font-size: 0.9rem;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-top: 30px;
}

.footnote_desktop {
	@include sm-down {
		display: none;
	}
}

.footnote_mobile {
	@include md-up {
		display: none;
	}

	margin: 2rem auto 0 0;
}

.desktop_cardhint_wrapper {
	@include sm-down {
		display: none;
	}
}

.desktop_cardhint_wrapper {
	@include sm-down {
		display: none;
	}
}
.mobile_cardhint_wrapper {
	display: flex;
	justify-content: center;
	padding-top: 1rem;
	@include md-up {
		display: none;
	}
}
