@import 'styles/layout-tokens';
@import 'styles/mixins/fonts-2024';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';

.grid {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: $spacing-md;

	@include xs-only {
		grid-template-columns: repeat(2, 1fr);
	}
}

.container {
	background: white;
	padding: $spacing-xl $spacing-lg;
	margin: 0 auto;

	> * {
		max-width: 90rem;
		margin-inline: auto;
	}

	.heading {
		@include headline3;

		text-align: center;
		text-wrap: balance;
		color: $black;
		max-width: 30ch;
		margin: 0 auto $spacing-lg;

		> span {
			opacity: 0.3;
		}

		@include sm-down {
			@include headline5;
			margin: 0 auto $spacing-md;
			max-width: 20ch;
		}
	}

	.cta_wrapper {
		margin-top: $spacing-lg;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	@include sm-down {
		padding: $spacing-lg $spacing-sm;
	}

	.wrapper {
		position: relative;

		//&::after {
		//	content: '';
		//	display: block;
		//	z-index: 2;
		//	position: absolute;
		//	// This makes the gradient cover an area that's slightly larger than the container, to
		//	// account for bottom-right card rendering slightly outside of the container when it's
		//	// skewed/rotated.
		//	inset: -32px;
		//	pointer-events: none;
		//	background: linear-gradient(to bottom, rgba(255, 255, 255, 0%) 40%, rgba(255, 255, 255, 95%) 90%);
		//
		//	@include xs-only {
		//		background: linear-gradient(to bottom, rgba(255, 255, 255, 0%) 60%, rgba(255, 255, 255, 95%) 95%);
		//	}
		//}
	}
}

.masonry_item {
	padding: 0;

	> div,
	a {
		display: block;
		width: 100%;
	}

	> a {
		background-color: #ececec;
		border-radius: 6px;
	}

	.quote_card {
		display: flex;
	}

	@include xs-only {
		p,
		a,
		span,
		strong,
		h2,
		h3,
		div {
			font-size: 10px;
			line-height: 1.2;
		}

		:global(.article_content),
		:global(.wall-of-love-article) {
			min-height: 0;
			aspect-ratio: 390 / 690;
			padding: $spacing-sm;

			p,
			a,
			span,
			strong,
			h2,
			h3,
			div {
				font-size: 10px;
				line-height: 1.2;
			}
		}

		:global(.wall-of-love-tweet) {
			padding: $spacing-xs;
		}
	}
}
