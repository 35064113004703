@import 'styles/mixins/fonts-2024';
@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';

$scale-amount: 1.05;
$rotate-amount: 0; //2.5deg;

.temp_widget {
	img {
		max-height: 35rem;
		border-radius: 10px;
	}

	width: auto;
	max-width: 90%;

	@include md-up {
		&:hover {
			transform: scale($scale-amount) rotate(-$rotate-amount);
		}
		box-shadow: 0 20.55px 43.669px 0 rgba(0, 0, 0, 0.1), 0 79.631px 79.631px 0 rgba(0, 0, 0, 0.09), 0 179.811px 107.887px 0 rgba(0, 0, 0, 0.05), 0 319.808px 128.437px 0 rgba(0, 0, 0, 0.01),
			0 500.903px 139.996px 0 rgba(0, 0, 0, 0);
	}

	border-radius: 0.6815rem;

	transition: all 0.25s ease-out;
}

.vitals_widget_container {
	@include md-up {
		&:hover {
			.vitals_image {
				transform: rotate($rotate-amount);
			}

			.vitals_graph {
				transform: rotate(-$rotate-amount);
			}

			transform: scale($scale-amount);
		}
	}

	* {
		transition: all 0.25s ease-out;
	}

	transition: all 0.25s ease-out;

	position: relative;

	@include sm-down {
		margin: 4rem 0 0;
	}
}

.vitals_image {
	max-width: 29.5rem;
	max-height: 30rem;

	border-radius: 0.625rem;

	img {
		height: 100%;
		width: 100%;
		border-radius: 0.625rem;
	}

	box-shadow: 0px 16px 34px 0px rgba(0, 0, 0, 0.1), 0px 62px 62px 0px rgba(0, 0, 0, 0.09), 0px 140px 84px 0px rgba(0, 0, 0, 0.05), 0px 249px 100px 0px rgba(0, 0, 0, 0.01),
		0px 390px 109px 0px rgba(0, 0, 0, 0);

	position: relative;

	// top: -6rem;
	// left: -4rem;

	@include sm-down {
		top: -4rem;
		left: 0;
		max-width: 100%;
		box-shadow: none;

		img {
			height: 100%;
			width: 85%;
			border-radius: 0.625rem;
		}
	}
}

.vitals_graph {
	border-radius: 1rem;

	position: absolute;
	bottom: -6rem;
	right: -4rem;
	max-width: 80%;
	height: 49.5%;

	@include sm-down {
		bottom: -1rem;
		right: 0rem;
		width: 18rem;
		height: unset;
	}
}

.alarm_widget_container {
	@include md-up {
		&:hover {
			.alarm_image {
				transform: rotate($rotate-amount);
			}

			.alarm_notification,
			.snoring_notification {
				transform: rotate(-$rotate-amount);
			}

			transform: scale($scale-amount);
		}
	}

	* {
		transition: all 0.25s ease-out;
	}

	transition: all 0.25s ease-out;

	position: relative;

	@include sm-down {
		margin: 0 auto 1rem;
	}
}

.alarm_image {
	max-width: 24rem;
	max-height: 27rem;

	border-radius: 0.625rem;

	img {
		border-radius: 0.625rem;
		height: 100%;
		width: 100%;
	}

	box-shadow: 0px 16px 34px 0px rgba(0, 0, 0, 0.1), 0px 62px 62px 0px rgba(0, 0, 0, 0.09), 0px 140px 84px 0px rgba(0, 0, 0, 0.05), 0px 249px 100px 0px rgba(0, 0, 0, 0.01),
		0px 390px 109px 0px rgba(0, 0, 0, 0);

	@include sm-down {
		max-width: 100%;
		box-shadow: none;
	}
}

.alarm_notification {
	border-radius: 0.75rem;

	width: 70%;
	height: 31%;

	position: absolute;
	bottom: -2rem;

	left: -2rem;

	@include sm-down {
		bottom: -1rem;
		left: -1rem;
	}
}

.snoring_notification {
	border-radius: 0.75rem;

	width: 70%;
	height: 40%;

	position: absolute;
	bottom: -2rem;

	left: -2rem;

	@include sm-down {
		bottom: -1rem;
		left: -1rem;
	}
}
