@import 'styles/constants.scss';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/theme';
@import 'styles/mixins/fonts-2023';
@import 'styles/colors-2023';
@import 'styles/mixins/button-styles-2023';
@import 'styles/layout-tokens';

.container {
	width: 300px; //Using px because Masonry is defined off pixels
	border-radius: 20px;
	box-shadow: rgba(0, 0, 0, 0.05) 2px 2px 12px;
	background-color: #f4f4f4;
	position: relative;
	overflow: hidden;
	isolation: isolate;

	color: $neutral-black;

	&:hover {
		.shade {
			opacity: 1;
		}

		.bg {
			transform: scale(1.025);
		}

		box-shadow: rgba(0, 0, 0, 0.25) 4px 4px 12px;

		text-decoration: none !important;

		.video_button {
			opacity: 1;
		}
	}

	@include xs-only {
		width: 100%;
	}

	transition: box-shadow 0.25s ease-in-out;
}

/*
----------------------------------------
   Article Card
----------------------------------------
 */

.bg {
	position: absolute;
	inset: 0;
	z-index: -1;

	transition: transform 0.25s ease-in-out;

	img {
		width: 100%;
		height: 100%;
	}
}

.shade {
	position: absolute;
	inset: 0;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 1) 100%);

	opacity: 0.8;
	z-index: 1;

	transition: all 0.25s ease-in-out;
}

.play_button {
	position: absolute;
	inset: 0;
	z-index: 2;

	display: flex;
	justify-content: center;
	align-items: center;

	svg {
		height: 4rem;
		width: auto;
		filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.25));
	}
}

.article_content {
	color: $neutral-white;
	min-height: 33rem;
	padding: 2rem;

	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	h2 {
		@include subheading3;
	}

	p {
		@include body5;
	}
}

.article_logo_container {
	> * {
		height: 1rem;
		width: auto;
	}
	margin-bottom: 1rem;
}

.video_button {
	@include button-base;
	position: absolute;
	top: 1rem;
	right: 1rem;
	padding: 0.5rem;
	z-index: 2;

	background-color: rgba(0, 0, 0, 0.25);
	border-radius: 1000px;

	opacity: 0;

	&:hover,
	&:focus-visible {
		opacity: 1;
	}

	svg {
		height: 2rem;
	}
}

.video {
	position: absolute;
	inset: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	object-fit: cover;
}

/*
----------------------------------------
   Tweet Card
----------------------------------------
 */

.tweet_content_container {
	padding: 1rem;
}

.tweet_logo_container {
	position: absolute;
	top: 1rem;
	right: 1rem;
	z-index: 1;

	> * {
		height: 1rem;
		max-width: 1rem;
	}
}

.tweet_header {
	display: grid;
	grid-template-columns: 2.5rem 1fr;
	gap: 0.5rem;
	margin-bottom: 0.5rem;
}

.tweet_header_picture {
	img {
		width: 2.5rem;
		height: 2.5rem;
		border-radius: 9999px;
		overflow: hidden;
	}
}

.tweet_header_text {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.tweet_header_text p {
	@include body7;
	color: #8e8e8e;
	display: flex;
	align-items: center;

	strong {
		color: $neutral-black;
	}
}

.verified_container {
	margin: 0 0.25rem;
	display: flex;
	align-items: center;
}

.tweet_content {
	margin-bottom: 0.5rem;
	font-size: 15px;
	color: black;

	strong {
		color: $blue-primary;
		font-weight: normal;
	}
}

.tweet_image_container {
	margin: 0.5rem 0;
	border-radius: 10px;
	overflow: hidden;

	video {
		width: 100%;
		display: block;
	}
}

.tweet_footer p {
	@include body8;
	color: #8e8e8e;
}

/*
----------------------------------------
   CTA Card
----------------------------------------
 */

.cta_badge_container {
	display: flex;
	justify-content: center;
	margin: 0 auto 0.5rem;
}

.cta_badge {
	@include body8;
	font-weight: 700;
	color: $solid-blue;
	display: inline-block;
	text-align: center;

	text-transform: uppercase;

	border-radius: 0.3125rem;
	background: linear-gradient(91deg, rgba(1, 78, 168, 0.05) -14.01%, rgba(1, 59, 139, 0.05) 53.53%, rgba(1, 42, 109, 0.05) 115.67%);

	padding: 0.25rem 0.5rem;
}

.cta_card_content {
	padding: 1rem;
	margin-top: 1rem;
}

.cta_headline {
	@include subheading2;
	text-align: center;

	margin-bottom: 1rem;
}

.cta_subheadline {
	@include body6;
	text-align: center;
	margin-bottom: 1rem;
}

.cta_card_cta {
	width: 100%;
	margin-bottom: 1rem;
}

/*
----------------------------------------
   Filter
----------------------------------------
 */

.filter_container {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.5rem;
	min-height: 3.5rem;

	//transition: all 0.5s ease;

	margin-left: 1rem;
	margin-right: 1rem;

	@include sm-up {
		gap: 1rem;
	}

	z-index: 100;
}

.filter_item {
	@include button-base;
	@include subheading6;

	padding: 0.5rem 1.125rem;
	max-width: 14rem;

	position: relative;
	display: inline-flex !important;
	align-items: center;

	overflow: hidden;

	border-radius: 20rem;

	@include sm-up {
		@include subheading5;
	}

	@include md-up {
		@include subheading4;
		padding: 0.5rem 2rem;
		font-weight: 400;
	}

	pointer-events: auto;
	white-space: nowrap;

	background: rgba(0, 0, 0, 0.12);
	color: $neutral-black;

	&:hover,
	&:focus-visible {
		background-color: $neutral-black;
		color: $neutral-white;
	}

	transition: all 0.25s;
	font-weight: 400;
}

.selected {
	background-color: $neutral-black;
	color: $neutral-white;
}

.filter_outer {
	-webkit-overflow-scrolling: touch;

	> * {
		pointer-events: none;
		margin: 0.5rem;
		margin-left: 1rem;
	}

	display: flex;

	align-items: center;

	@media (min-width: 32rem) {
		justify-content: center;
	}

	background-color: white;
	transition: all 0.25s ease-in-out;
}

div.basic_card.basic_card {
	border-radius: 6px;
	background-color: #ececec;
	color: black;
	padding: $spacing-sm;
	display: grid;
	grid-template-rows: 35px 1fr;
	align-items: stretch;
	row-gap: 15px;

	@include xs-only {
		row-gap: 10px;
		grid-template-rows: 25px 1fr;
		padding: $spacing-xs;
	}

	p {
		margin-bottom: 0;
		font-weight: 300;
		line-height: 1.2;
		//font-family: NeueMontreal, sans-serif;
	}

	> div {
		display: flex;
		align-items: center;
		max-height: 100%;
		column-gap: 10px;
	}

	picture,
	img {
		height: 100%;
	}
}

div.quote_card {
	border-radius: 6px;
	background-color: #ececec;
	color: black;
	padding: $spacing-sm;
	display: flex;
	flex-direction: column;
	row-gap: 1rem;

	@include xs-only {
		row-gap: 0.75rem;
		padding: $spacing-xs;
	}

	p {
		margin-bottom: 0;
		font-weight: 300;
		line-height: 1.2;
		//font-family: NeueMontreal, sans-serif;
		display: flex;
		flex-direction: column;

		strong,
		span {
			font-size: 0.8em;
		}

		strong {
			font-weight: bold;
		}

		span {
			color: #8e8e8e;
		}
	}

	img {
		max-width: 100%;
		border-radius: 6px;
	}
}
