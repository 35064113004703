@import 'styles/mixins/fonts-2024';
@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-decorated';

.container {
	color: $white;
	overflow: hidden;
	background-color: $white;

	@include sm-down {
		background-color: #101010;
	}
}

.header {
	padding: $spacing-xl $spacing-sm 0 $spacing-sm;

	h2,
	p {
		@include headline3;
		@include sm-down {
			@include headline4;
		}
	}

	p {
		opacity: 0.5;
		text-wrap: balance;
	}

	text-align: center;

	margin-bottom: $spacing-md;
}

.header em {
	@include blue_tag;
	margin-bottom: 1.5rem;
}

.benefits_wrapper {
	margin: 0 auto;
	display: flex;
	justify-content: center;
}

.benefits {
	margin: 0;
	display: flex;
	justify-content: center;
	gap: 6rem;
	list-style: none;

	@include sm-down {
		flex-direction: column;
		gap: 1rem;

		width: 100%;
		max-width: 40rem;

		svg {
			filter: invert(1);
		}
	}

	padding: 0 0 $spacing-xl;
}

.bottom_text {
	padding: 0 $spacing-sm $spacing-xl;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	max-width: 570px;

	p {
		@include body3;
		margin-inline: auto;
		text-align: center;
		color: $black;

		@include sm-down {
			@include body4;
			color: white;
		}
	}

	em {
		opacity: 0.5;
		font-style: normal;
	}
}

.top_content {
	position: relative;
	z-index: 1;
	background-color: #101010;
}

.bottom_container {
	position: relative;
	z-index: 1;

	background-color: $white;
	color: $black;

	padding: $spacing-xl $spacing-md 0;

	@include sm-down {
		padding: $spacing-md $spacing-sm 0;
		background: #101010;
		color: white;
	}
}

.benefit {
	text-align: center;
	border-radius: 0.625rem;
	border-radius: 10px;
	background: #f9f7f7;
	padding: 34px;

	svg {
		width: 2rem;
		height: 2rem;
		margin-bottom: 1rem;
	}

	color: $black;

	@include sm-down {
		border-radius: 10px;
		background: #181818;
		color: white;
		width: 100%;
	}
}

.benefit h3 {
	@include body1;
	margin-bottom: 1rem;

	@include sm-down {
		@include body3;
		margin-bottom: 1rem;
		color: white;
	}
}

.benefit p {
	@include body3;
	max-width: 28ch;
	color: rgba(0, 0, 0, 0.5);

	@include sm-down {
		@include body4;
		color: rgba(255, 255, 255, 0.5);
	}
}

.top_cta {
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	justify-content: center;
	align-items: center;
	gap: 1rem;

	padding-bottom: 2rem;

	@include xs-only {
		grid-template-columns: 1fr;
		justify-items: center;
	}
}

.cta_container {
	display: flex;
	justify-content: flex-end;
}

.affirm_text {
	@include body5;
	line-height: normal;
	flex: 0;

	svg {
		max-height: 1.25em;
		display: inline;
	}

	span {
		display: inline-block;
		height: 1.25em;
		width: 3em;
	}
}

.cropped_image_container {
	display: flex;
	justify-content: center;
	overflow: visible;
	max-height: 800px;
	height: 60vh;
	margin-bottom: calc(80vh - 60vh - 50px);
	background-color: #101010;
}

.cropped_image {
	img {
		height: 80vh;
		width: auto;
		max-height: 1000px;
		object-position: top center;
		z-index: 5;
		position: relative;

		transform: translateX(1.5%);
	}
}

.bottom_cta_container {
	display: flex;
	justify-content: center;
	align-items: center;

	padding: 4rem 0 2rem;

	@include md-up {
		display: none;
	}
}
