@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';
@import 'styles/mixins/fonts-decorated';

$max-width: 80rem;
.container {
	background-color: $white;
	color: $black;

	text-align: center;

	> * {
		max-width: $max-width;

		margin-inline: auto;
	}

	padding: 0 2rem 8rem;

	@include sm-down {
		padding: 0 1.5rem 4rem;
	}
}

.container header {
	h2 {
		@include headline3;
		max-width: 30ch;

		margin-inline: auto;
	}

	p {
		@include blue_tag;
		margin-bottom: 1.5rem;
	}

	margin-bottom: 4rem;

	@include sm-down {
		h2 {
			@include headline5;
		}

		margin-bottom: 2rem;
	}
}

.benefits {
	list-style: none;

	margin: 0;
	padding: 0;
}

.video_container {
	aspect-ratio: 16 / 9;

	position: relative;

	max-height: 668px;
	max-width: 100%;

	video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 1rem;
	}
}

.benefits {
	list-style: none;

	display: inline-grid;
	padding: 4rem;

	grid-template-columns: repeat(3, 1fr);
	row-gap: 4rem;
	column-gap: 2rem;

	width: 100%;

	@include sm-down {
		grid-template-columns: repeat(2, 1fr);
		column-gap: 1rem;
		padding: 2rem 1rem;
	}
}

.benefits li {
	display: flex;
	flex-direction: column;
	text-align: left;

	transform: translateX(25%);

	@include xs-only {
		transform: none;
	}
}

.benefits p {
	@include body2;

	max-width: 13.1ch;

	@include sm-down {
		@include body4;
		max-width: 12.9ch;
	}
}

.benefits svg {
	width: 3rem;
	height: 3rem;

	margin-bottom: 1rem;

	@include sm-down {
		width: 2rem;
		height: 2rem;
	}
}

.cta_container {
	display: flex;
	justify-content: center;
	align-items: center;

	margin-top: 2rem;
}

.fullVideo {
	object-fit: cover;
	height: 100%;
	width: 100%;
}

.videoContainer {
	background-color: $white;
	display: flex;
	justify-content: center;
	align-items: center;

	height: 100%;
	width: 100%;

	video {
		object-fit: cover;
		height: 100%;
		width: 100%;
	}
}
