@import 'fonts-2024';

@mixin blue_tag {
	display: inline-flex;
	@include eyebrow1;

	color: #5289fe;

	border-radius: 0.25rem;
	background: rgba(82, 137, 254, 0.3);

	padding: 0.25rem 0.5rem;
}

@mixin orange_tag {
	display: inline-flex;
	@include tag1;

	padding: 0.3rem 0.6rem;
	color: #ff5b00;
	background-color: #ff5b0019;
	border-radius: 0.25rem;

	align-items: flex-end; // This looked better than center

	text-transform: uppercase;
}
